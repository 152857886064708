<template>
  <div>
    <div class="announcement-group">
      <div class="form-group">
        <b>Numarul anunțului</b>
        <input
          type="text"
          class="vInput"
          v-model="announcement.announcementNumber"
        />
      </div>
      <div class="form-group">
        <b>Criteriul de atribuire</b>
        <v-select
          v-model="announcement.attributionCriteria"
          :options="[
            'Preţul cel mai scăzut',
            'Costul cel mai scăzut',
            'Cel mai bun raport calitate-preţ',
            'Cel mai bun raport calitate-cost',
          ]"
        >
        </v-select>
      </div>
    </div>

    <div class="announcement-group">
      <div class="form-group">
        <b>Data anunțului</b>
        <date-picker
          v-model="announcement.announcementDate"
          type="date"
          format="DD-MM-YYYY"
          valueType="date"
          popup-class="bigger-index"
        />
      </div>
      <div class="form-group">
        <b>Data limită pentru transmiterea ofertelor</b>
        <div style="display: flex; gap: 5px;">
          <date-picker
            v-model="announcement.expireDate"
            type="date"
            format="DD-MM-YYYY"
            valueType="date"
            popup-class="bigger-index"
          />
          <date-picker
            v-model="announcement.expireDate"
            type="time"
            format="HH:mm"
            popup-class="bigger-index"
          />
        </div>
      </div>
    </div>

    <div class="announcement-group">
      <div class="form-group">
        <b>Limba de redactare a ofertei</b>
        <input
          type="text"
          class="vInput form-disabled"
          v-model="announcement.language"
          :disabled="true"
        />
      </div>
      <div class="form-group">
        <b>Sursa de finanțare a contractului</b>
        <input
          type="text"
          class="vInput form-disabled"
          v-model="announcement.financingSource"
          :disabled="true"
        />
      </div>
      <div class="form-group">
        <b>Perioada de valabilitate a ofertelor (zile)</b>
        <CustomInput v-model="announcement.valability"></CustomInput>
      </div>
    </div>

    <div class="announcement-group">
      <div class="form-group">
        <b>Propunerea tehnică</b>
        <textarea
          v-model="announcement.technicalProposal"
          class="vInput"
          rows="5"
        ></textarea>
      </div>
      <div class="form-group">
        <b>Propunerea financiară</b>
        <textarea
          v-model="announcement.financialProposal"
          class="vInput"
          rows="5"
        ></textarea>
      </div>
    </div>

    <div class="announcement-group">
     <div>
      <div class="announcement-radio">
        <input
          v-model="announcement.updatePrice"
          id="updatePriceYes"
          type="radio"
          name="updatePrice"
          value="DA"
        />
        <label for="updatePriceYes">DA</label>
        <input
          v-model="announcement.updatePrice"
          id="updatePriceNo"
          type="radio"
          name="updatePrice"
          value="NU"
        />
        <label for="updatePriceNo">NU</label>
        <span> se acceptă actualizarea prețului contractului</span>
      </div>
      <div class="announcement-radio">
        <input
          v-model="announcement.otherOffers"
          id="otherOffersYes"
          type="radio"
          name="otherOffers"
          value="DA"
        />
        <label for="otherOffersYes">DA</label>
        <input
          v-model="announcement.otherOffers"
          id="otherOffersNo"
          type="radio"
          name="otherOffers"
          value="NU"
        />
        <label for="otherOffersNo">NU</label>
        <span> se acceptă oferte alternative</span>
      </div>
     </div>
    </div>
  </div>
</template>
<script>
import DatePicker from "@/mixins/DatePicker.js";
import CustomInput from "@/components/CustomInput";
export default {
  mixins: [DatePicker],

  components: { CustomInput },

  props: {
    initial: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      announcement: {
        announcementNumber: "",
        announcementDate: "",
        expireDate: "",
        attributionCriteria: "",
        language: "română",
        financingSource: "buget",
        valability: "",
        technicalProposal: "",
        financialProposal: "",
        updatePrice: "",
        otherOffers: "",
      },
    };
  },

  watch: {
    announcement: {
      handler(val) {
        this.$emit("input", {
          ...val,
        });
      },
      deep: true,
      immediate: true,
    },
    deep: true,
    immediate: true,
  },
  computed: {},
  methods: {},
  created() {
    if (this.initial) {
      this.announcement = {
        ...this.initial,
        announcementDate: new Date(this.initial.announcementDate),
        expireDate: new Date(this.initial.expireDate),
      };
    }
  },
};
</script>
<style lang="scss">
.announcement-group {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  .form-group {
    flex: 1;
  }
  textarea {
    resize: vertical;
  }
  .announcement-radio {
    display: flex;
    align-items: center;
    gap: 3px;
    input {
      margin: 0 !important;
    }
  }
}
.verbal-process-container {
  border: 1px solid;
  border-radius: 5px;
  padding: 15px 10px;
  position: relative;
  margin-bottom: 10px;
  .delete-provider-icon {
    display: none;
    position: absolute;
    top: -8px;
    right: -7px;
    background-color: white;
  }
}

.verbal-process-container:hover {
  .delete-provider-icon {
    display: initial;
  }
}
</style>
