<template>
  <div>
    <div class="verbal-process-group">
      <div class="form-group">
        <b>Data procesului verbal</b>
        <date-picker
          v-model="verbalProcess.verbalProcessDate"
          type="date"
          format="DD-MM-YYYY"
          valueType="date"
          popup-class="bigger-index"
        />
      </div>
      <div class="form-group">
        <b>Criteriul de atribuire</b>
        <v-select
          v-model="verbalProcess.attributionCriteria"
          :options="[
            'Preţul cel mai scăzut',
            'Costul cel mai scăzut',
            'Cel mai bun raport calitate-preţ',
            'Cel mai bun raport calitate-cost',
          ]"
        >
        </v-select>
      </div>
    </div>

    <div class="verbal-process-group">
      <div class="form-group">
        <b
          >Modalitatea de solicitare / obținere oferte financiare și tehnice:</b
        >

        <v-select
          v-model="verbalProcess.requestModality"
          :options="infoModality"
        >
        </v-select>
      </div>
      <div class="form-group">
        <b>Modalitatea de primire / obținere oferte financiare și tehnice:</b>

        <v-select
          v-model="verbalProcess.receiveModality"
          :options="infoModality"
        >
        </v-select>
      </div>
    </div>

    <div class="verbal-process-group">
      <div class="form-group">
        <b>Membru 1:</b>
        <v-select
          v-model="verbalProcess.memeberRole[0]"
          :options="memeberRolesView"
        >
        </v-select>
      </div>
      <div class="form-group">
        <b>Membru 2:</b>
        <v-select
          v-model="verbalProcess.memeberRole[1]"
          :options="memeberRolesView"
        >
        </v-select>
      </div>
    </div>

    <div>
      <b>Oferte</b>
      <div
        class="verbal-process-container"
        v-for="(item, index) of verbalProcess.verbalProcessInstitutionProvider"
      >
        <i
          @click="deleteProvider(index)"
          v-tooltip="'Șterge furnizor'"
          class="fas fa-minus-circle delete-provider-icon"
          style="cursor: pointer;  color: red"
        ></i>
        <div class="verbal-process-group">
          <div class="form-group noMB">
            <div>
              <b>CUI furnizor</b>
            </div>
            <div class="RForm">
              <input
                type="text"
                class="vInput"
                v-model="item.institutionProviderCui"
              />
              <i @click="getByCui(index)" class="fas fa-search"></i>
            </div>
          </div>
          <div class="verbal-process">
            <b>Nume furnizor</b>
            <input
              type="text"
              class="vInput form-disabled"
              v-model="item.institutionProviderName"
              :disabled="true"
            />
          </div>

          <div class="verbal-process">
            <b>Valoare totală fără TVA</b>
            <CustomInput
              v-model="item.totalValueWithoutTva"
              @input="updateTotalValueWithTva(index)"
            ></CustomInput>
          </div>

          <div class="verbal-process">
            <b>Cotă TVA</b>
            <input
              min="1"
              max="100"
              type="text"
              class="vInput procent"
              v-model="item.tvaValue"
              @input="($event) => updateTva($event, index)"
            />
          </div>

          <div class="verbal-process">
            <b>Valoare totală cu TVA</b>
            <CustomInput
              v-model="item.totalValueWithTva"
              :disabled="true"
            ></CustomInput>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="verbalProcess.verbalProcessInstitutionProvider.length < 10"
      style="display: flex; justify-content: flex-end;"
      @click="addProvider"
    >
      <i
        v-tooltip="'Adaugă furnizor'"
        class="fas fa-plus-circle"
        style="cursor: pointer;  color: #39f"
      ></i>
    </div>
  </div>
</template>
<script>
import DatePicker from "@/mixins/DatePicker.js";
import CustomInput from "@/components/CustomInput";
import { USERS_INSTITUTION, INSTITUTION_PROVIDERS } from "../api.js";
export default {
  mixins: [DatePicker],

  components: { CustomInput },

  props: {
    initial: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      memeberRoles: [],
      infoModality: [
        "E-mail/Telefonic/Fax/Adrese",
        "Studiu de piat/Cataloage/Internet",
        "Catalog electronic S.E.A.P",
      ],
      verbalProcess: {
        verbalProcessDate: "",
        requestModality: "",
        receiveModality: "",
        attributionCriteria: "",
        memeberRole: [],
        verbalProcessInstitutionProvider: [
          {
            totalValueWithoutTva: "",
            tvaValue: "",
            totalValueWithTva: "",
            institutionProviderName: "",
            institutionProviderCui: "",
          },
        ],
      },
    };
  },

  watch: {
    verbalProcess: {
      handler(val) {
        
        this.$emit("input", {
          ...val,
          verbalProcessInstitutionProvider: val.verbalProcessInstitutionProvider.map(
            (el) => ({
              ...el,
              tvaValue: this.removePercentage(el.tvaValue),
            })
          ),
        });
      },
      deep: true,
      immediate: true,
    },
    deep: true,
    immediate: true,
  },
  computed: {
    memeberRolesView() {
      return this.memeberRoles.filter(
        (el) =>
          el._userId != this.verbalProcess.memeberRole[0]?._userId &&
          el._userId != this.verbalProcess.memeberRole[1]?._userId &&
          el.id != this.getUserRole(true)
      );
    },
  },
  methods: {
    getByCui(index) {
      const institutionProviderCui = this.verbalProcess
        .verbalProcessInstitutionProvider[index].institutionProviderCui;
    
      const error = (msg) => {
        this.$toastr.e(msg || "Furnizorul nu a fost gasit.");
        this.verbalProcess.verbalProcessInstitutionProvider[
          index
        ].institutionProviderName = "";
        this.setLoad(false);
      };

      this.setLoad(true);

      INSTITUTION_PROVIDERS.getByCui(institutionProviderCui)
        .then((res) => {
          console.log(res);
          if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
            this.verbalProcess.verbalProcessInstitutionProvider[
              index
            ].institutionProviderName = res.data.name;
            this.setLoad(false);
          } else {
            error(res?.data?.response || null);
          }
        })
        .catch(error);
    },

    updateTva(event, index) {
      let tvaValue = this.verbalProcess.verbalProcessInstitutionProvider[index]
        .tvaValue;

      if (event.inputType == "deleteContentBackward" && tvaValue) {
        tvaValue = tvaValue.slice(0, -1);
        tvaValue += "%";
      } else {
        event.target.value = this.removePercentage(event.target.value);

        tvaValue = event.target.value.match(/^\d+\.?\d{0,2}$/);

        if (tvaValue) {
          tvaValue += "%";
        }
      }

      this.verbalProcess.verbalProcessInstitutionProvider[
        index
      ].tvaValue = tvaValue;

      this.updateTotalValueWithTva(index);
    },

    updateTotalValueWithTva(index) {
      let tvaValue = this.verbalProcess.verbalProcessInstitutionProvider[index]
        .tvaValue;

      const totalValueWithoutTva = this.verbalProcess
        .verbalProcessInstitutionProvider[index].totalValueWithoutTva;

      let totalValueWithTva =
        (Number(this.removePercentage(tvaValue)) / 100) *
          Number(totalValueWithoutTva) +
          Number(totalValueWithoutTva) ?? 0;

      this.verbalProcess.verbalProcessInstitutionProvider[
        index
      ].totalValueWithTva = totalValueWithTva;
    },

    removePercentage(val) {
      if (!val) return "";
      return val.replace("%", "");
    },

    deleteProvider(idenx) {
      this.verbalProcess.verbalProcessInstitutionProvider.splice(idenx, 1);
    },
    addProvider() {
      this.verbalProcess.verbalProcessInstitutionProvider.push({
        totalValueWithoutTva: "",
        tvaValue: "",
        totalValueWithTva: "",
        institutionProviderName: "",
        institutionProviderCui: "",
      });
    },

    async getUsers() {
      USERS_INSTITUTION()
        .then((res) => {
          if (Array.isArray(res?.data?.result)) {
            this.memeberRoles = res.data.result
              .flatMap(
                (e) =>
                  e?.userRoles?.map((i) => {
                    if (i && i.role == "responsabil_achizitii") {
                      return {
                        ...i,
                        id: i.id ?? this.makeid(6),
                        _userId: e.id,
                        label:
                          this.readUserObject(e) +
                          (i.department?.name
                            ? ` — ${i.department?.name}`
                            : "") +
                          (i.role ? ` — ${this.changeRoleView(i.role)}` : ""),
                      };
                    }
                    return null;
                  }) || []
              )
              .filter((el) => el)
              .sort((a, b) => a.label - b.label);
          }
        })
        .catch();
    },
  },
  created() {
    this.getUsers();

    if (this.initial) {
      this.verbalProcess = {
        ...this.initial,
        verbalProcessDate: new Date(this.initial.verbalProcessDate),
      };

      this.verbalProcess.memeberRole = this.verbalProcess.memeberRole.flatMap(
        (e) => {
          return {
            ...e,
            id: e.id ?? this.makeid(6),
            _userId: e.user.id,
            label:
              this.readUserObject(e.user) +
              (e.department?.name ? ` — ${e.department?.name}` : "") +
              (e.role ? ` — ${this.changeRoleView(e.role)}` : ""),
          };
        }
      );

      this.verbalProcess.verbalProcessInstitutionProvider = this.verbalProcess.verbalProcessInstitutionProvider.map(
        (el) => {
          return {
            ...el,
            tvaValue: el.tvaValue + "%",
            institutionProviderName: el.institutionProvider.name,
            institutionProviderCui: el.institutionProvider.cui,
          };
        }
      );
    }
  },
};
</script>
<style lang="scss">
.verbal-process-group {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: space-between;
  .form-group {
    flex: 1;
  }
}
.verbal-process-container {
  border: 1px solid;
  border-radius: 5px;
  padding: 15px 10px;
  position: relative;
  margin-bottom: 10px;
  .delete-provider-icon {
    display: none;
    position: absolute;
    top: -8px;
    right: -7px;
    background-color: white;
  }
}

.verbal-process-container:hover {
  .delete-provider-icon {
    display: initial;
  }
}
</style>
